<template>
  <div class="user-income">
    <div class="header">
      <div class="group">
        <div class="item">
          <div class="title">
            <div class="icon">
              <img :src="icon1" class="img">
            </div>
            总计收益 (元)
          </div>
          <div class="cont">
            <div class="price">{{ userIncome.totalIncome | changeUnit }}</div>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <div class="icon">
              <img :src="icon2" class="img">
            </div>
            余额 (元)
            <div class="btn" @click="goWithdraw">提现</div>
          </div>
          <div class="cont">
            <div class="price">{{ userIncome.accountBalance | changeUnit }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="data-list">
        <div class="group">
          <div class="head" @click="goInviteList">
            <div class="title">邀约总览</div>
            <div class="cont">
              <van-icon name="arrow" />
            </div>
          </div>
          <div class="list">
            <div class="item">
              <div class="title">
                <div class="icon">
                  <img :src="icon5" class="img">
                </div>
                <div class="text">我的邀约</div>
              </div>
              <div class="cont">{{ userIncome.myInviteCount }} 个</div>
            </div>
            <div class="item">
              <div class="title">
                <div class="icon">
                  <img :src="icon3" class="img">
                </div>
                <div class="text">好友邀约</div>
              </div>
              <div class="cont">{{ userIncome.friendInviteCount }} 个</div>
            </div>
          </div>
        </div>
        <div class="group">
          <div class="head">
            <div class="title">今日概览</div>
          </div>
          <div class="list">
            <div class="item">
              <div class="title">
                <div class="icon">
                  <img :src="icon5" class="img">
                </div>
                <div class="text">我的邀约</div>
              </div>
              <div class="cont">{{ userIncome.dayMyInviteCount }} 个</div>
            </div>
            <div class="item">
              <div class="title">
                <div class="icon">
                  <img :src="icon3" class="img">
                </div>
                <div class="text">好友邀约</div>
              </div>
              <div class="cont">{{ userIncome.dayFriendInviteCount }} 个</div>
            </div>
            <div class="item">
              <div class="title">
                <div class="icon">
                  <img :src="icon4" class="img">
                </div>
                <div class="text">今日收益</div>
              </div>
              <div class="cont" style="color: red;">{{ userIncome.dayTotalIncome | changeUnit }}元</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg" />
    </div>
  </div>
</template>
<script>
import icon1 from '@/assets/user/sjzx_con1_icon_sy@2x.png'
import icon2 from '@/assets/user/sjzx_con1_icon_ye@2x.png'
import icon3 from '@/assets/user/sjzx_con2_icon_hyyy@2x.png'
import icon4 from '@/assets/user/sjzx_con2_icon_jrsy@2x.png'
import icon5 from '@/assets/user/sjzx_con2_icon_wdyy@2x.png'
import { getUserIncome } from '@/api/user'
export default {
  filters: {
    // 将单位转化为元
    changeUnit(val) {
      if (!val) return 0.0
      return (val / 100).toFixed(2)
    }
  },
  data() {
    return {
      userIncome: {},
      icon1,
      icon2,
      icon3,
      icon4,
      icon5
    }
  },
  mounted() {
    this.getUserIncome()
  },
  methods: {
    async getUserIncome() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getUserIncome()
      loading.clear()
      this.userIncome = res.datas
    },
    goWithdraw() {
      this.$router.push({ name: 'userWithdrawal' })
    },
    goInviteList() {
      this.$router.push({ name: 'userInviteDetail' })
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  color: #fff;
  .group {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    .item {
      width: 164px;
      height: 90px;
      padding: 20px 0 0 20px;
      border-radius: 10px;
      background: linear-gradient(131deg, #a7d5ff 0%, #53acff 100%);
      box-sizing: border-box;
      &:first-child {
        background: linear-gradient(131deg, #ffd8b0 0%, #ffb468 100%);
      }
      .title {
        display: flex;
        align-items: center;
        font-size: 12px;
        .icon {
          font-size: 0;
          padding-right: 6px;
          img {
            width: 17px;
          }
        }
        .btn {
          display: inline-block;
          margin-left: 8px;
          font-size: 12px;
          padding: 4px 10px;
          border: 1px solid #fff;
          border-radius: 10px;
        }
      }
      .cont {
        display: flex;
        align-items: center;
        padding-top: 8px;
        .price {
          font-size: 24px;
        }
      }
    }
  }
}
.content {
  position: relative;
  padding: 0 0.266667rem;
  .data-list {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 0 0.24rem 0.733333rem 0.24rem;
    box-sizing: border-box;
    border-radius: 0.2rem;
    line-height: 1;
    .group {
      .head {
        display: flex;
        align-items: center;
        padding: 0.5rem 0 0.4rem 0;
        .title {
          flex: 1;
          font-size: 0.426667rem;
        }
        .cont {
          display: flex;
          font-size: 0.48rem;
          color: #cbcbcd;
        }
      }
      .list {
        padding: 0 0.266667rem;
        border-radius: 0.2rem;
        background: #fff;
        box-shadow: 0px 0.08rem 0.16rem rgba(0, 0, 0, 0.06);
        .item {
          display: flex;
          align-items: center;
          padding: 0.32rem 0;
          &:last-child {
            border: 0;
          }
          .title {
            display: flex;
            align-items: center;
            flex: 1;
            .icon {
              .img {
                width: 0.853333rem;
                height: 0.853333rem;
              }
            }
            .text {
              padding-left: 0.266667rem;
              font-size: 0.373333rem;
              color: #323233;
            }
          }
          .cont {
            font-size: 0.426667rem;
            color: #323233;
          }
        }
      }
    }
  }
}
</style>
